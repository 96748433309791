import React, { useEffect, useRef } from "react";

import useSetBackgroundHelper from "../../helpers/setBackgroundHelper";

const HeaderSmallComponent = ({ title, subtitle, cover, setterHeightCallback }) => {
    const headerRef = useRef();
    useSetBackgroundHelper(headerRef, cover);
    
    useEffect(() => {
        // Used to get header height for handling navbar opacity
        setterHeightCallback(headerRef.current.offsetHeight);
    }, [setterHeightCallback]);
    
    return (
        <>
            <div className="header-darken-filter"></div>
            <header ref={headerRef} className="d-flex justify-content-center align-items-center header-small-wrapper">
                <div className="container text-center text-white header-content">
                    <h1 className="display-3 ls-3 mb-0">
                        {title}
                    </h1>
                    <p className="fw-light fs-3-integral ls-3 mb-0">
                        {subtitle}
                    </p>
                </div>
            </header>
        </>
    );
};

export default HeaderSmallComponent;
