import React, { useEffect, useRef } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ChevronDownIcon from "../../assets/medias/svg/icons/icon-chevron-down.svg";
import IntegralLogo from "../../assets/medias/logos/logo-integral-full.svg";

const HeaderFullComponent = ({ setterHeightCallback, pageContentRef }) => {
    const {t} = useTranslation();
    const headerRef = useRef();
    
    useEffect(() => {
        // Used to get header height for handling navbar opacity
        setterHeightCallback(headerRef.current.offsetHeight);
    }, [setterHeightCallback]);
    
    return (
        <header ref={headerRef} className="d-flex justify-content-center align-items-center header-full-wrapper">
            <div className="container text-center text-white">
                <div className="mb-5 logo-wrapper"><IntegralLogo /></div>

                <h1 className="display-5 ls-4">
                {t("app.header.businessSolutions")}<br className="d-md-none" />{t("app.header.custom")}
                </h1>
                <p className="lead ls-3">
                {t("app.header.conceiveSolutionsTogether")}<br className="d-none d-sm-inline d-xl-none" />{t("app.header.suitActivity")}
                </p>
                 
                <ChevronDownIcon className="chevron" role="button" onClick={() => pageContentRef.current.scrollIntoView(true)} />
            </div>

        </header>
    );
};

export default HeaderFullComponent;
