import React from "react";
import createDOMPurify from "dompurify";

import useSVGsQuery from "../../data-loader/SVGsDataLoader";

const isBrowser = typeof window !== "undefined";

const SvgBlockComponent = ({ name, className }) => {
    // Handle 'dangerouslySetInnerHTML' vulnerability to XSS
    const purify = isBrowser ? createDOMPurify(window) : null;
    
    const { allFile } = useSVGsQuery();
    const rawFile = allFile.nodes.find( (node) => node.base === name );

    return (
        <div className={className}
             dangerouslySetInnerHTML={{ __html: isBrowser ? purify.sanitize(rawFile.svg.content) : "" }} />
    );
};

export default SvgBlockComponent;
