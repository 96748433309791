import React, { useState } from "react";

import HeaderFullComponent from "./HeaderFullComponent";
import HeaderSmallComponent from "./HeaderSmallComponent";
import NavbarComponent from "../NavbarComponent";

const HeaderWrapperComponent = ({ title, subtitle, cover, pageContentRef, hasLogoAnimation, type = "small" }) => {
    const [headerHeight, setHeaderHeight] = useState(0);

    const HeaderSelector = () => {
        switch(type) {
            case "full":
                return <HeaderFullComponent setterHeightCallback={setHeaderHeight} pageContentRef={pageContentRef} />;
            default:
                return <HeaderSmallComponent setterHeightCallback={setHeaderHeight} title={title} subtitle={subtitle} cover={cover} />;
        }
    };

    return (
        <>
            <NavbarComponent headerHeight={headerHeight} hasLogoAnimation={hasLogoAnimation} />
            <HeaderSelector />
        </>
    );
};

export default HeaderWrapperComponent;
