import { useEffect } from "react";

import useImagesQuery from "../data-loader/ImagesDataLoader";

const useSetBackgroundHelper = (ref, cover) => {
    const { allFile } = useImagesQuery();
    
    const rawImage = allFile.nodes.find( (node) => node.base === cover );
    const path = rawImage.childImageSharp.gatsbyImageData.images.fallback.src;

    useEffect(() => {
        ref.current.style.backgroundImage = "url(\"" + path + "\")";
    }, [path, ref]);
};

export default useSetBackgroundHelper;
