import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

import FacebookIcon from "../../assets/medias/svg/icons/icon-facebook.svg";
import GithubIcon from "../../assets/medias/svg/icons/icon-github.svg";
import LinkedinIcon from "../../assets/medias/svg/icons/icon-linkedin.svg";
import RssIcon from "../../assets/medias/svg/icons/icon-rss.svg";
import TwitterIcon from "../../assets/medias/svg/icons/icon-twitter.svg";

const SocialNetworksBlockComponent = ({ svgThemeClass }) => {
    const {t} = useTranslation();

    const link1 = "https://twitter.com/IntegralWeb69";
    const link2 = "https://www.facebook.com/integralweb69";
    const link3 = "https://github.com/IntegralService";
    const link4 = "https://www.linkedin.com/company/11263361/";
    const link5 = "https://www.integral-service.fr/rss.xml";

    return (
        <div className={"network-svgs " + svgThemeClass}>
            <a href={link1} target="_blank" rel="noreferrer" aria-label={t("app.socialNetworks.twitterArialabel")} title={t("app.socialNetworks.twitterInfo")}><TwitterIcon className="me-2" /></a>
            <a href={link2} target="_blank" rel="noreferrer" aria-label={t("app.socialNetworks.facebookArialabel")} title={t("app.socialNetworks.facebookInfo")}><FacebookIcon className="me-2" /></a>
            <a href={link3} target="_blank" rel="noreferrer" aria-label={t("app.socialNetworks.githubArialabel")} title={t("app.socialNetworks.githubInfo")}><GithubIcon className="me-2" /></a>
            <a href={link4} target="_blank" rel="noreferrer" aria-label={t("app.socialNetworks.linkedinArialabel")} title={t("app.socialNetworks.linkedinInfo")}><LinkedinIcon className="me-2" /></a>
            <a href={link5} target="_blank" rel="noreferrer" aria-label={t("app.socialNetworks.rssArialabel")} title={t("app.socialNetworks.rssInfo")}><RssIcon /></a>
        </div>
    );
};

export default SocialNetworksBlockComponent;
