import React from "react";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import AddressBlockComponent from "./blocks/AddressBlockComponent";
import SocialNetworksBlockComponent from "./blocks/SocialNetworksBlockComponent";

const FooterComponent = () => {
    const {t} = useTranslation();

    return (
        <footer className="section-dark-theme">
            <div className="container-fluid container-lg px-6 px-sm-5 px-md-4 px-lg-auto py-footer">
                <div className="row">
                    <div className="col-sm-6 col-md-4 mb-5 mb-md-0">
                        <h3 className="fw-light fs-4-integral ls-3 mb-3">{t("app.footer.ourCompany")}</h3>

                        <ul className="fw-light px-0">
                            <li><Link to="/about">{t("app.footer.whoWeAre")}</Link></li>
                            <li><Link to="/services">{t("app.footer.ourServices")}</Link></li>
                            <li><Link to="/blog">{t("app.footer.ourBlog")}</Link></li>
                            <li><Link to="/realisation">{t("app.footer.references")}</Link></li>
                            <li><Link to="/technologies-web">{t("app.footer.technologies")}</Link></li>
                            <li><Link to="/contact">{t("app.footer.contactUs")}</Link></li>
                            <li><Link to="/ml">{t("app.footer.legalNotices")}</Link></li>
                        </ul>
                    </div>

                    <div className="col-sm-6 col-md-4 mb-5 mb-md-0">
                        <h3 className="fw-light fs-4-integral ls-3 mb-3">{t("app.footer.ourExpertise")}</h3>

                        <ul className="fw-light mt-3 px-0">
                            <li><Link to="/application-web">{t("app.services.webApp")}</Link></li>
                            <li><Link to="/portail-web-collaboratif">{t("app.services.collabPortal")}</Link></li>
                            <li><Link to="/application-iot">{t("app.services.iotApp")}</Link></li>
                            <li><Link to="/application-mobile">{t("app.services.mobileApp")}</Link></li>
                            <li><Link to="/site-internet">{t("app.footer.showcaseSite")}</Link></li>
                            <li><Link to="/e-commerce">{t("app.footer.eCommerceSite")}</Link></li>
                            <li><Link to="/expert-symfony">{t("app.footer.symfony")}</Link></li>
                        </ul>
                    </div>

                    <div className="row col-md-4">
                        <div className="col-sm-6 col-md-12 mb-5 mb-sm-0 mb-md-5">
                            <h3 className="fw-light fs-4-integral ls-3 mb-3">{t("app.footer.contactUs")}</h3>

                            <AddressBlockComponent containerClassName="mb-2" />
                            <span className="fw-light fs-1-integral">
                                {t("app.global.contactUppercase")}{t("app.brand.contactEmail")}<br/>
                                {t("app.global.supportUppercase")}{t("app.brand.supportEmail")}
                            </span>
                        </div>

                        <div className="col-sm-6 col-md-12">
                            <h3 className="fw-light fs-4-integral ls-3 mb-3">{t("app.footer.followUs")}</h3>
                            <SocialNetworksBlockComponent svgThemeClass="svg-dark" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 text-center py-3 bottom-bar">
                <div className="container">
                    <div className="row d-flex fs-1-integral justify-content-between">
                        <div className="col-12 col-md-auto mb-1 mb-md-0">
                            <p className="mb-0">
                                {t("app.footer.bottom.madeBy")}
                                <Link className="bottom-link" to="/">{t("app.brand.integralServiceName")}</Link>
                                {t("app.footer.bottom.webStudio")}
                            </p>
                        </div>
                        <div className="col-12 col-md-auto">
                            <p className="mb-0">
                                {t("app.footer.bottom.group")}
                                <a className="bottom-link" href={t("app.brand.integralSystemUrl")} target="_blank" rel="noreferrer">
                                    {t("app.brand.integralSystemName")}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;
