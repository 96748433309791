import React from "react";
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

import SvgBlockComponent from "./SvgBlockComponent";

const LanguagesPickerBlockComponent = () => {
    const {t} = useTranslation();
    const { language, languages, originalPath } = useI18next();
    let swappedLanguage = languages[0];

    // Get the opposite language between "en" and "fr"
    if(language === languages[0]) {
        swappedLanguage = languages[1];
    }
    if(language === languages[1]) {
        swappedLanguage = languages[0];
    }

    return(
        <Link className="languages-picker" language={swappedLanguage} title={t("app.link.changeLanguage")} to={originalPath}>
            <SvgBlockComponent className="w-2-rem" name={"flag-" + swappedLanguage + ".svg"} />
        </Link>
    );
};

export default LanguagesPickerBlockComponent;
