import { graphql, useStaticQuery } from "gatsby";

const useSVGsQuery = () => {
    // Get all files from "/assets/medias/svg"
    const { allFile } = useStaticQuery(graphql`
        query SVGsQuery {
            allFile(filter: {sourceInstanceName: {eq: "svg"}}) {
                nodes {
                    base
                    svg {
                        content
                    }
                }
            }
        }
    `);

    return ({ allFile });
};

export default useSVGsQuery;
