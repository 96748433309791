import React, { useEffect, useRef } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import LanguagesPickerBlockComponent from "./blocks/LanguagesPickerBlockComponent";

import ChevronIcon from "../assets/medias/svg/icons/icon-chevron-right.svg";
import IntegralLogo from "../assets/medias/logos/logo-integral-full.svg";
import MailIcon from "../assets/medias/svg/icons/icon-mail.svg";

const NavbarComponent = ({ headerHeight, hasLogoAnimation }) => {
    const {t} = useTranslation();
    const brandRef = useRef();
    const navRef = useRef();
    const sideMenuRef = useRef();
    const togglerRef = useRef();

    const handleScroll = () => {
        // To prevent error when switching page
        if(navRef.current == null) {
            return;
        }

        const opacity = (window.scrollY || window.pageYOffset) / headerHeight; // Get scroll factor
        navRef.current.style.backgroundColor = `rgba(0, 0, 0, ${opacity})`;

        brandRef.current.classList.remove('now-visible');
        if (!hasLogoAnimation || opacity >= 0.3) {
            brandRef.current.classList.add('now-visible');
        }
    };

    const toggleMenu = () => {
        togglerRef.current.classList.toggle("side-menu-open");
        sideMenuRef.current.classList.toggle("active");
    };
    const closeMenu = () => {
        togglerRef.current.classList.remove("side-menu-open");
        sideMenuRef.current.classList.remove("active");
    };

    useEffect(() => {
        // Initiate navbar opacity state
        handleScroll();
        // Then update it when necessary
        window.addEventListener('scroll', () => {
            if ((window.scrollY || window.pageYOffset) <= headerHeight) {
                handleScroll();
            } else if (navRef.current != null) {
                navRef.current.style.backgroundColor = `rgba(0, 0, 0, 1)`;
            }
        });
    }, [headerHeight, hasLogoAnimation]);

    return (
        <nav ref={navRef} className="navbar navbar-expand-md fixed-top p-0 navbar-wrapper">
            <div className="container-lg align-items-center">
                <Link ref={brandRef} className="d-flex logo-default" title={t("app.link.redirectIndexPage")} to="/"><IntegralLogo /></Link>

                <ul className="navbar-nav d-none d-md-flex align-items-center">
                    <li className="p-1 p-lg-2"><Link className="nav-link" to="/about">{t("app.title.aboutUs")}</Link></li>
                    <li className="p-1 p-lg-2"><Link className="nav-link" to="/technologies-web">{t("app.title.expertise")}</Link></li>

                    <li className="p-2 dropdown">
                        <Link className="nav-link" to="/services">{t("app.title.services")}</Link>
                        <div className="dropdown-content">
                            <Link className="nav-link" to="/application-web">{t("app.services.webApp")}</Link>
                            <Link className="nav-link" to="/portail-web-collaboratif">{t("app.services.collabPortal")}</Link>
                            <Link className="nav-link" to="/e-commerce">{t("app.services.eCommerceSolution")}</Link>
                            <Link className="nav-link" to="/application-iot">{t("app.services.iotApp")}</Link>
                            <Link className="nav-link" to="/application-mobile">{t("app.services.mobileApp")}</Link>
                        </div>
                    </li>

                    <li className="p-1 p-lg-2"><Link className="nav-link" to="/realisation">{t("app.title.references")}</Link></li>
                    <li className="p-1 p-lg-2"><Link className="nav-link" to="/blog">{t("app.title.blog")}</Link></li>

                    <li><LanguagesPickerBlockComponent /></li>
                    <li className="p-2"><Link className="nav-link" title={t("app.link.redirectContactPage")} to="/contact"><MailIcon className="w-2-rem" /></Link></li>
                </ul>

                <div className={"d-flex d-md-none align-items-center"}>
                    <LanguagesPickerBlockComponent />
                    <Link className="nav-link" title={t("app.link.redirectContactPage")} to="/contact"><MailIcon className="w-2-rem" /></Link>
                    
                    <button ref={togglerRef} type="button" className="navbar-toggler"
                            aria-label={t("app.navbar.toggleSideMenu")} onClick={toggleMenu}>
                        <span className="navbar-burger-icon">
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </span>
                    </button>
                </div>
                
                <div ref={sideMenuRef} className="d-flex flex-column side-menu-wrapper">
                    <Link to="/"><IntegralLogo className="mx-4 logo" /></Link>

                    <ul className="flex-fill fs-5">
                        <li className="p-2"><Link to="/about" onClick={closeMenu}>{t("app.title.aboutUs")}</Link></li>
                        <li className="p-2"><Link to="/technologies-web" onClick={closeMenu}>{t("app.title.expertise")}</Link></li>

                        <li className="p-2"><Link to="/services" onClick={closeMenu}>{t("app.title.services")}</Link></li>
                        <ul className="menu-sub-link svg-dark">
                            <li className="px-1 py-2 fs-7"><ChevronIcon /><Link to="/application-web" onClick={closeMenu}>{t("app.services.webApp")}</Link></li>
                            <li className="px-1 py-2 fs-7"><ChevronIcon /><Link to="/portail-web-collaboratif" onClick={closeMenu}>{t("app.services.collabPortal")}</Link></li>
                            <li className="px-1 py-2 fs-7"><ChevronIcon /><Link to="/e-commerce" onClick={closeMenu}>{t("app.services.eCommerceSolution")}</Link></li>
                            <li className="px-1 py-2 fs-7"><ChevronIcon /><Link to="/application-iot" onClick={closeMenu}>{t("app.services.iotApp")}</Link></li>
                            <li className="px-1 py-2 fs-7"><ChevronIcon /><Link to="/application-mobile" onClick={closeMenu}>{t("app.services.mobileApp")}</Link></li>
                        </ul>

                        <li className="p-2"><Link to="/realisation" onClick={closeMenu}>{t("app.title.references")}</Link></li>
                        <li className="p-2"><Link to="/blog" onClick={closeMenu}>{t("app.title.blog")}</Link></li>
                    </ul>

                    <Link className="d-flex btn btn-outline-primary justify-content-center" to="/contact" onClick={closeMenu}>{t("app.button.contactUsInfinitiveForm")}</Link>
                </div>
            </div>
        </nav>
    );
};

export default NavbarComponent;
