const Config = {
    /* MAIN meta datas */
    siteTitle: "Integral Service", // Site title.
    siteTitleAlt: "Integral Service votre studio Web", // Alternative site title for SEO.
    siteDescription: "Studio Web Lyonnais pour vos applications sur-mesure", // Website description used for RSS feeds/meta description tag.
    siteLogo: "/src/assets/medias/logos/logo-integral-seo.png", // Logo used for SEO.
    siteUrl: "https://www.site-url.tld", // Domain of your website without pathPrefix.
    
    /* ----- JSON-LD related ----- */
    organizationName: "Integral Service",
    organizationLegalName: "INTEGRAL SERVICE",
    organizationFoundingDate: "2003",
    // Used within "founders" attribute
    founderName1: "Bernard GACHE",
    founderName2: "Vincent GACHE",
    // Used within "address" attribute
    addressStreet: "36 Rue Emile Decorps Bâtiment 23",
    addressLocality: "Pôle Pixel",
    addressPostalCode: "69100",
    addressCountry: "FRA",
    // Used within "contactPoint" attribute
    contactTelephone: "+33437697200",
    contactType: "customer service",
    contactEmail: "info@integral-service.fr",
    // Used within "sameAs" attribute
    accountFacebook: "https://www.facebook.com/pcindustriel",
    accountInstagram: "https://www.instagram.com/integralsystem69/",
    accountLinkedin: "https://www.linkedin.com/company/integral-system",
    accountTwitter: "https://twitter.com/pcindustriel",
    accountYoutube: "https://www.youtube.com/channel/UCqntyqAH_4yThQGbMYfXxCw",
    /* ----- ---- ----- ---- ----- */
    
    /* Open Graphs */
    siteFBAppID: "000000000000000", // FB Application ID for using app insights (112572482745004)
    userTwitter: "@IntegralWeb69", // Optionally renders "Follow Me" in the UserInfo segment.
    
    /* Miscellaneous */
    siteRss: "/rss.xml", // Path to the RSS file. NOT USED ATM
    blogPostDir: "article", // The name of directory that contains your posts. NOT USED ATM
    googleAnalyticsID: "AA-00000000-0", // GA tracking ID. NOT USED ATM (UA-46886702-1)
    disqusShortname: "integral-service", // Disqus shortname. NOT USED ATM
    postDefaultCategoryID: "Tech", // Default category for posts. NOT USED ATM
    userName: "Integral Service", // Username to display in the author segment. NOT USED ATM
    userLocation: "Lyon, France", // User location to display in the author segment. NOT USED ATM
    copyright: "Copyright © 2022. Integral Service" // Copyright string for the footer of the website and RSS feed. NOT USED ATM
};

export default Config;
