import React from "react";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import MailIcon from "../../assets/medias/svg/icons/icon-mail.svg";

const BannerBlockComponent = ({ catchPhrase }) => {
    const {t} = useTranslation();

    return(
        <div className="d-flex ls-2 fs-3 fw-bolder justify-content-center align-items-center py-4 px-5 p-sm-5 banner-wrapper font-bebas">
            <span className="text-center me-3 catch-phrase">{catchPhrase}</span>
            
            <Link className="fw-bolder bg-white ls-1 pt-2 px-3 button" to="/contact">
                <MailIcon className="w-3-rem pe-2 icon" />
                <span>{t("app.button.contactUsImperativeForm")}</span>
            </Link>
        </div>
    );
};

export default BannerBlockComponent;
