import { graphql, useStaticQuery } from "gatsby";

const useImagesQuery = () => {
    // Get all files from "/assets/medias/images"
    const { allFile } = useStaticQuery(graphql`
        query ImagesQuery {
            allFile(filter: {sourceInstanceName: {eq: "images"}}) {
                nodes {
                    base
                    childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                }
            }
        }
    `);

    return ({ allFile });
};

export default useImagesQuery;
