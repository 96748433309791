import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AddressBlockComponent = ({ containerClassName }) => {
    const {t} = useTranslation();

    return(
        <address className={"fw-light fs-1-integral " + containerClassName}>
            <strong><span className="text-uppercase">{t("app.brand.integralServiceName")}</span></strong><br/>
            {t("app.brand.streetAddress")}<br/>
            {t("app.brand.cityAddress")}<br/>
            {t("app.brand.phoneNumber")}<br/>
        </address>
    );
};

export default AddressBlockComponent;
