import Helmet from "react-helmet";
import React from "react";

import config from "../../siteConfig";

const SEOComponent = ({ pageSEO, pageNode, articleSEO, articleNode }) => {
    let title;
    let description;
    let image;
    let currentUrl;

    // Here we get datas from frontmatter or set default values
    if (articleSEO) {
        title = articleNode.title;
        description = articleNode.metaDescription; // or excerpt if empty
        image = config.siteUrl + "/" + articleNode.cover;
        currentUrl = config.siteUrl + articleNode.slug;
    } else if (pageSEO) {
        title = pageNode.metaTitle;
        description = pageNode.metaDescription;
        image = config.siteUrl + "/" + pageNode.cover;
        currentUrl = config.siteUrl + pageNode.slug;
    } else {
        title = config.siteTitle;
        description = config.siteDescription;
        image = config.siteUrl + config.siteLogo;
        currentUrl = config.siteUrl;
    }

    const schemaOrgJSONLD = [
        {
            "@context": "http://schema.org",
            "@type": "WebSite",
            url: config.siteUrl,
            name: title,
            alternateName: config.siteTitleAlt
        },
        {
            "@context": "http://schema.org",
            "@type": "Organization",
            url: config.siteUrl,
            logo: "https://www.integral-system.fr/assets/medias/logos/logo-integral-seo.png",
            name: config.organizationName,
            legalName: config.organizationLegalName,
            foundingDate: config.organizationFoundingDate,
            founders:[
                {
                    "@type": "Person",
                    name: config.founderName1
                },
                {
                    "@type": "Person",
                    name: config.founderName2
                }],
            address: {
                "@type": "PostalAddress",
                streetAddress: config.addressStreet,
                addressLocality: config.addressLocality,
                postalCode: config.addressPostalCode,
                addressCountry: config.addressCountry
            },
            contactPoint: [{
                "@type": "ContactPoint",
                telephone: config.contactTelephone,
                contactType: config.contactType,
                email: config.contactEmail
            }],
            sameAs: [
                config.accountFacebook,
                config.accountInstagram,
                config.accountLinkedin,
                config.accountTwitter,
                config.accountYoutube
            ]
        }
    ];
    if (articleSEO || pageSEO) {
        schemaOrgJSONLD.push([
            {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                    {
                        "@type": "ListItem",
                        position: 1,
                        item: {
                            "@id": currentUrl,
                            name: title,
                            image
                        }
                    }
                ]
            },
            {
                "@context": "http://schema.org",
                "@type": "BlogPosting",
                url: config.siteUrl,
                name: title,
                alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
                headline: title,
                image: {
                    "@type": "ImageObject",
                    url: image
                },
                description
            }
        ]);
    }

    return (
        <Helmet htmlAttributes={{ lang: 'fr' }}>
            {/*Required meta tags*/}
            <meta charSet="utf-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="robots" content="noindex, nofollow" />

            {/* General tags */}
            <title>{pageSEO || articleSEO ? title + " | " + config.siteTitle : config.siteTitleAlt}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <link rel="canonical" href={currentUrl} />

            {/* Favicon tags */}
            {/* <link rel="icon" href="/favicon.ico" type="image/x-icon" sizes="any" />
            <link rel="icon" href="/favicons/icon.svg" type="image/svg+xml" />
            <link rel="apple-touch-icon" href="/favicons/apple-touch-icon.png" /> */}

            {/* Schema.org tags */}
            <script type="application/ld+json">
                {JSON.stringify(schemaOrgJSONLD)}
            </script>

            {/* OpenGraph tags */}
            <meta property="og:url" content={articleSEO || pageSEO ? currentUrl : config.siteUrl} />
            {articleSEO ? <meta property="og:type" content="article" /> : null}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="fb:app_id" content={config.siteFBAppID} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={config.userTwitter} />
            <meta name="twitter:creator" content={config.userTwitter} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
    );
};

export default SEOComponent;
